import React, { useState, useEffect } from 'react';
import LogoForm from '../assets/img/logo-form.png';
import '../css/login.css';
import { Navigate, Link } from 'react-router-dom';
import { newInvoice } from '../api/invoice';
import useAuth from '../hooks/useAuth';
import { getAccessToken } from '../api/auth';
import { getUserEnterprise } from '../api/enterprise';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getMonthInvoice } from '../api/configAdmin';
import FormSaldo from '../components/FormsInvoice/FormSaldo';
import FormInvoice from '../components/FormsInvoice/FormInvoice';

const NewInvoicePage = () => {

	const {t} = useTranslation();

	const { user, isLoading } = useAuth();

	const token = getAccessToken();

	const [ invoice, setInvoice ] = useState({
		date: '',
		expirationDate: '',
		refSupplier: '',
		description: '',
		price: '',
		file: '',
		enterpriseID: ''
	});

	const [ enterprise, setEnterprise ] = useState([]);

	const [ file, setFile ] = useState('');

	const [ total, setTotal ] = useState(0);

	const [ showButton, setShowButton ] = useState(true);


	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [ exito, setExito ] = useState(false);

	const { date, expirationDate, refSupplier, description, price } = invoice;

	const [ ref, setRef ] = useState('');

	const handleChange = e => {
		setInvoice({
			...invoice,
			[e.target.name] : e.target.value
		})
		
	};

	const handleFile = e => {
		setFile(...file, e.target.files[0]);
	}



	useEffect(() => {

		setTotal((Number(price) + ((Number(enterprise.iva)/100)*Number(price)) - ((Number(enterprise.irpf)/100)*Number(price))).toFixed(2))

	}, [price, enterprise.iva, enterprise.irpf]);

	useEffect(() => {

		async function fetchDatos() {
			await getUserEnterprise(token).then(response => {
				setEnterprise(response.enterprise);
			});
		}

		fetchDatos();

	}, [token]);

	useEffect(() => {

		async function fetchDatos() {
			await getMonthInvoice(token).then(response => {
				setRef(response.ref);
			});
		}

		fetchDatos();

	}, [token]);

	const handleSubmit = async e => {
		
		e.preventDefault();

		setShowButton(false);

		if(date.trim() === '' || expirationDate.trim() === '' || refSupplier.trim() === '' || description.trim() === '' || price.trim() === '') {
			setError({
				msg: t('Todos los campos son obligatorios'),
				status: true
			});
			setShowButton(true);
			return;
		}

		if(!file) {
			setError({
				msg: t('Debes adjuntar la factura para poder darla de alta.'),
				status: true
			});
			setShowButton(true);
			return;
		} 

		if (date > expirationDate) {
			setError({
				msg: t('La fecha de vencimiento no puede ser previa a la fecha de emisión.'),
				status: true
			});
			setShowButton(true);
			return;
		}

		if (date > datePermited(ref)) {
			setError({
				msg: t('Solo se pueden subir facturas de este mes o los anteriores. Contacta con soporte.'),
				status: true
			});
			setShowButton(true);
			return;
		}

		invoice.file = file;
		invoice.enterpriseID = enterprise._id;

		const result = await newInvoice(invoice, token);

		

	if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
			setShowButton(true);
		} else {
			setError({
				msg: "",
				status: false
			});
			setInvoice({
				date: '',
				expirationDate: '',
				refSupplier: '',
				description: '',
				price: '',
				file: ''
			});
			setExito(true);
			setShowButton(true);
			setTimeout(function() { 
				window.location.reload();
			}, 3000);
		}
	}

	const handleSubmitSaldo = async e => {
		e.preventDefault();

		if(description.trim() === '' || price.trim() === '') {
			setError({
				msg: t('Todos los campos son obligatorios'),
				status: true
			});
			return;
		}

		invoice.date = moment().format("YYYY-MM-DD");
		invoice.expirationDate = moment("YYYY-MM-DD");
		invoice.refSupplier = 'S';
		invoice.enterpriseID = enterprise._id;
		
		const result = await newInvoice(invoice, token);

	if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
			
		} else {
			setError({
				msg: "",
				status: false
			});
			setInvoice({
				date: '',
				expirationDate: '',
				refSupplier: '',
				description: '',
				price: '',
				file: ''
			});
			setExito(true);
		}
	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if(!enterprise) return 'Cargando...';

	if(enterprise.payment === 'saldo') {
		return(
			<div className="d-flex justify-content-center align-items-center fullpage">
				<div className="d-flex flex-column align-items-center">
					<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
					<div className="card w-100">
						<div className="card-body">
							{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
							<FormSaldo
								handleSubmitSaldo={handleSubmitSaldo}
								handleChange={handleChange}
								invoice={invoice}
								enterprise={enterprise}
								t={t}
							/>
						</div>
					</div>
					<div className="mt-3">
						<Link className="col-7" to="/">
							<span>{t('Volver al listado de facturas')}</span>
						</Link>
					</div>
				</div>
			</div>
		)
	}

	return ( 
		<div className="d-flex justify-content-center align-items-center fullpage">
			<div className="d-flex flex-column align-items-center">
				<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
				<div className="card w-100">
					<div className="card-body">
						{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
						<FormInvoice 
							handleSubmit={handleSubmit}
							t={t}
							invoice={invoice}
							enterprise={enterprise}
							handleChange={handleChange}
							total={total}
							handleFile={handleFile}
							exito={exito}
							showButton={showButton}
						/>
					</div>
				</div>
				<div className="mt-3 pb-4">
					<Link className="col-7" to="/">
						<span>{t('Volver al listado de facturas')}</span>
					</Link>
				</div>
			</div>
			
		</div>
	 );
}
 
export default NewInvoicePage;

const datePermited = (refMonth) => {

	if (refMonth === 'E') {
		return '2025-01-31'
	} else if (refMonth === 'F') {
		return '2025-02-28'
	} else if (refMonth === 'M') {
		return '2025-03-31'
	} else if (refMonth === 'A') {
		return '2025-04-30'
	} else if (refMonth === 'MY') {
		return '2025-05-31'
	} else if (refMonth === 'J') {
		return '2025-06-30'
	} else if (refMonth === 'JL') {
		return '2025-07-31'
	} else if (refMonth === 'AG') {
		return '2025-08-31'
	} else if (refMonth === 'S') {
		return '2025-09-30'
	} else if (refMonth === 'O') {
		return '2025-10-31'
	} else if (refMonth === 'N') {
		return '2025-11-30'
	} else if (refMonth === 'D') {
		return '2025-12-31'
	} 
	
}