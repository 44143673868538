import { useState } from 'react';
import { downloadExtendedReportInvoicesOut } from '../../api/paymentReport';

const ExtendedReportOut = ({token}) => {

	const [ form, setForm ] = useState({
		first: '',
		last: '',
		month: ''
	});

	const [exito, setExito ] = useState(false);
	const [error, setError ] = useState(false);

	const { first, last, month } = form;

	const handleChange = e => {
		setForm({
			...form,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {

		e.preventDefault();

		if(month === '' || first === '' || last === '') {
			setError(true);
		}

		const result = await downloadExtendedReportInvoicesOut(form, token);

	}

	return ( 
		<div className="row mt-3">
			<div className="card">
				<div className="card-header bg-white mt-2">
					<h4>Descargar reporte para gestoría (R-A)</h4>
				</div> 
				<div className="card-body">
					<form className="row g-4" onSubmit={handleSubmit}>
						<p className="text-muted">
							Debes indicar la referencia inicial y final de las facturas que quieres pagar. Por ejemplo, desde la R-A15-E1 a la R-A15-E100, deberás indicar 1 y 100. También deberás indicar el mes.
						</p>
						<div className="col-auto">
							<p className="text-muted mb-0">Referencia inicial</p>
							<input 
								type="number" 
								className="form-control" 
								placeholder="Ref. inicial"
								name="first"
								value={first}
								onChange={handleChange}
							/>
						</div>
						<div className="col-auto">
							<p className="text-muted mb-0">Referencia final</p>
							<input 
								type="number" 
								className="form-control" 
								placeholder="Ref. final"
								name="last"
								value={last}
								onChange={handleChange}
							/>
						</div>
						<div className="col-auto">
							<p className="text-muted mb-0">Mes</p>
							<select className="form-select" name="month" value={month} onChange={handleChange}>
								<option value="">Mes</option>
								<option value="E">Enero</option>
								<option value="F">Febrero</option>
								<option value="M">Marzo</option>
								<option value="A">Abril</option>
								<option value="MY">Mayo</option>
								<option value="J">Junio</option>
								<option value="JL">Julio</option>
								<option value="AG">Agosto</option>
								<option value="S">Septiembre</option>
								<option value="O">Octubre</option>
								<option value="N">Noviembre</option>
								<option value="D">Diciembre</option>
							</select>
						</div>		
						<div className="col-auto d-flex align-items-center mt-4">
							<button type="submit" className="btn btn-primary mt-3">Descargar</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	 );
}
 
export default ExtendedReportOut;